<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="transparent"
    flat
    height="75"
  >
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="setDrawer(!drawer)"
    >
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>
    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="$route.name"
    />
    <v-spacer />

    <div class="mx-3" />
    <v-tooltip
      v-if="$route.name === 'Dashboard'"
      bottom
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          v-bind="attrs"
          class="ml-2"
          min-width="0"
          text
          v-on="on"
          @click="DashboardUpdateAll()"
        >
          <v-icon>
            mdi-refresh
          </v-icon>
        </v-btn>
      </template>

      <span>Tout mettre à jour</span>
    </v-tooltip>

    <v-btn
      class="ml-2"
      min-width="0"
      text
      @click="$vuetify.theme.dark = !$vuetify.theme.dark"
    >
      <v-icon>mdi-view-dashboard</v-icon>
    </v-btn>

    <v-btn
      class="ml-2"
      min-width="0"
      text
      @click="logout"
    >
      <v-icon>mdi-account-off</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
  // Components
  import { eventBus } from '@/main.js'

  // Utilities
  import { mapState, mapMutations } from 'vuex'

  export default {
    name: 'DashboardCoreAppBar',

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      ...mapState(['drawer']),
    },
    methods: {
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),
      logout () {
        this.$store.dispatch('auth/logout')
        this.$router.push('/login')
      },
      DashboardUpdateAll () {
        eventBus.$emit('DashboardUpdateAll')
      },
    },
  }
</script>
